import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import Layout from './layout/Layout';
import Loading from './components/loading/LoadingPage';
import 'aos/dist/aos.css';


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animations in ms
    });
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000); 
  // }, []);

  return (
    <div className='overflow-hidden'>
      {isLoading ? <Loading /> : <Layout />}
    </div>
  );

}

export default App;
