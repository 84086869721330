import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loading from '../components/loading/LoadingPage';

const Home = lazy(() => import('../pages/home/Home'));
const PrivacyPolicy = lazy(() => import('../components/privacyPolicy/PrivacyPolicy'));
const TermsCond = lazy(() => import('../components/T&C/TermsCond'));
const RiskDisc = lazy(() => import('../components/riskDisclaimer/RiskDesclaimer'));
const OutletComponent = lazy(() => import('./OutletComponent'));

function Layout() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<OutletComponent />}>
            <Route path="/" element={<Home />} />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
            <Route path="/Terms-and-Conditions" element={<TermsCond />} />
            <Route path="/Risk-Disclaimer" element={<RiskDisc />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default Layout;
