import React, { useEffect, useState } from 'react';
import loadingImage from '../../assets/common/LoadingPage.webp';

const Loading = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 1 : 100));
    }, 50); 

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen bg-gray-200 flex items-center justify-center">
      <img src={loadingImage} alt="Loading" className="absolute inset-0 w-full h-full object-cover" />
      <div className="absolute bottom-[20%] left-1/2 transform -translate-x-1/2 w-[80%] h-2 bg-[#090909] rounded-full overflow-hidden">
        <div
          className="h-full bg-[#FB9B37] rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Loading;
